var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _vm.loading
        ? _c("div", { staticClass: "loader-container" }, [
            _c(
              "div",
              { staticClass: "loading-wrapper" },
              [
                _c("v-progress-linear", {
                  attrs: {
                    value: "3",
                    height: "3",
                    indeterminate: "",
                    color: "black",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }