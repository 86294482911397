<template>
  <div>
    <Navbar />
    <div v-if="loading" class="loader-container">
      <!--  {{ customerType }} -->
      <div class="loading-wrapper">
        <v-progress-linear
          value="3"
          height="3"
          indeterminate
          color="black"
        ></v-progress-linear>
        <!--  {{ $route.params.type }} -->
       <!--  {{ $linkedinRedirectURL }} -->
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import Navbar from "@/components/Navbar/onboarding_navbar";
//import SignupInstructions from "./partials/signupInstructions.vue";

export default {
  components: {
    Navbar,
    //SignupInstructions,
    // AlertSuccess,
    //AlertError,
    //GoogleSignIn,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  // mixins: [postLogin, mixPanel],
  data() {
    return {
      loading: true,
      // evironment: process.env.NODE_ENV,
      //userReferralCode: null,
      //referralDetails: null,
    };
  },
  mounted() {
    this.setCustomerParamsType();
  },

  watch: {},
  methods: {
    //  ...mapActions("loaderManagement", ["setAuthenticatedLoader"]),
    ...mapActions("userManagement", [
      // "setAutheticatedUser",
      //"setUserReferralCode",
      //"setLinkedinLogin",
      "setCustomerType",
    ]),

    setCustomerParamsType() {
      // console.log(this.$route.params.type);
      if (this.$route.params.type) {
        let userType = this.$route.params.type;
        // alert(userType);
        let social = this.$route.params.social;
        let customerType =
          userType === "founder" ? 2 : userType === "expert" ? 1 : "";
        this.setCustomerType(customerType);
          if (social === "linkedin") {
           // alert(this.$linkedinRedirectURL)
          document.location.href = `${this.$linkedinRedirectURL}`;
        } else if (social === "google") {
          let url = `${process.env.VUE_APP_BACKEND_API}/auth/google/`;
          document.location.href = url;
        } else {
          document.location.href = process.env.VUE_APP_BASEURL;
        }
      }
    },
  },

  computed: {
    //...mapState("devOnboarding", ["registrationToken"]),
    //...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("userManagement", [
      "currentUser",
      // "referralCode",
      "customerType",
    ]),
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.loader-container {
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-wrapper {
  width: 400px;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
